<template>
  <div class="products">
    <div class="coming_soon">
      <img src="https://cdn.blockwar.io/web_assets/img/coming_soon.png" alt="" />
      <p>coming online soon...</p>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
// import { useStore } from "vuex";

export default defineComponent({
  name: "products",
  components: {},
  setup() {
    // let store = useStore();
    // let iframeSerc = ref("");
    onMounted(() => {});
    return {};
  },
});
</script>
<style scoped>
</style>
